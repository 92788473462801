$(document).on('turbolinks:load', function() {
  $("select[name='group[payment_method]']").on('change', function (event) {
    let payment_method = $("select[name='group[payment_method]']").val();
    if (payment_method == 'notice') {
      $(".paysys-api-settings").slideUp();
    } else {
      $(".paysys-api-settings").slideDown();
    }
  });
  $("select[name='group[payment_method]']").trigger('change');

  const Encoding = require('encoding-japanese');
  const Papa = require('papaparse');

  $("input:file[name='group[paysys_api_settings]']").on('change', function (event) {
    if (event.target.files.length >= 1) {
      var file = event.target.files[0];

      const reader = new FileReader;
      reader.onload = function (e) {
        var array = new Uint8Array(e.target.result);
        var encode = Encoding.detect(array);
        Papa.parse(file, {
          header: true,
          encoding: encode,
          skipEmptyLines: true,
          complete: function (results) {
            $("input[name='group[paysys_channel_id]']").val(results.data[0]['チャンネルID']);
            $("input[name='group[paysys_channel_secret_key]']").val(results.data[0]['チャンネル秘密鍵']);
            console.log("updated.");
          }
        })
      }
      reader.readAsArrayBuffer(file);
    }
  });
});
