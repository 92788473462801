function adjust_room_list($rooms, original) {
  var facility_id = $('select#facility_id').val()
  $rooms.html(original).find('option').each(function() {
    if (facility_id != $(this).data('facility-id')) {
      $(this).not(':first-child').remove()
    }
  })
}

function delete_facility_room() {
  var url = new URL(window.location.href)
  url.searchParams.delete('facility_id')
  url.searchParams.delete('room_id')
  history.replaceState('', '', url.href)
}

$(document).on('turbolinks:load', function() {
  var $rooms = $("select#room_id")
  var original = $rooms.html()
  adjust_room_list($rooms, original)
  $('select#facility_id').change(function() {
    adjust_room_list($rooms, original)
    if ($(this).val() == "") {
      $rooms.attr('disabled', 'disabled')
    } else {
      $rooms.removeAttr('disabled')
    }
    $('select#room_id').val('')
    $('#reservation_calendar').fullCalendar('refetchEvents')
    $('#raffle_calendar').fullCalendar('refetchEvents')
    delete_facility_room()
  })
  $('select#room_id').change(function() {
    delete_facility_room()
  })
})
