$(document).on('ready turbolinks:load', function() {
  const update_mynumber_card_status = function (mynumber_user_id) {
    $.ajax({
      url: '/users/mynumber_card_status',
      type: 'POST',
      data: { mynumber_user_id: mynumber_user_id },
      async: false,
      dataType: 'json',    
    })
    .done(function (data) {
      var data_stringify = JSON.stringify(data);
      var data_json = JSON.parse(data_stringify);
      my_status = data_json["status"];
      my_expires_on = data_json["expires_on"];
     
      document.getElementById('user_card_status').textContent = '';
      document.getElementById('user_card_expires_on').textContent = '';

      if (my_status == 'working') {
        document.getElementById('user_card_status').textContent = '運用中';
        document.getElementById('user_card_status').style.color = "#666666";
      } else if (my_status == 'suspended') {
        document.getElementById('user_card_status').textContent = '一時停止';
        document.getElementById('user_card_status').style.color = "red";
      } else if (my_status == 'obsoleted') {
        document.getElementById('user_card_status').textContent = '廃止';
        document.getElementById('user_card_status').style.color = "red";
      } else {
        document.getElementById('user_card_status').textContent = '未連携';
        document.getElementById('user_card_status').style.color = "#666666";
      }

      if (my_expires_on != null && my_expires_on.length > 0) {
        var setDate = new Date(my_expires_on + ' 23:59:59');
        var today = new Date();

        document.getElementById('user_card_expires_on').textContent = `${setDate.getFullYear()}年${("0"+(setDate.getMonth() + 1)).slice(-2)}月${("0"+(setDate.getDate())).slice(-2)}日`;
        if (setDate.getTime() < today.getTime()) {
          document.getElementById('user_card_expires_on').style.color = "red"
        } else {
          document.getElementById('user_card_expires_on').style.color = "#666666";
        }
      } else {
        document.getElementById('user_card_expires_on').textContent = '';
      }
    })
    .fail(function (){
      console.log('MyNumber: マイナンバーカード運用状況・有効期限の取得に失敗しました。')
    });
  }

    $("select[name='user[raffle_limit_type]']").change(function() {
      var val = $("select[name='user[raffle_limit_type]']").val();
      if (val != "none") {
        $(".user_raffle_limit_number").slideDown()
      } else {
        $(".user_raffle_limit_number").slideUp()
      }
    })
    $("select[name='user[raffle_limit_type]']").trigger('change');

    $("select[name='user[reservation_limit_type]']").change(function() {
      var val = $("select[name='user[reservation_limit_type]']").val();
      if (val != "none") {
        $(".user_reservation_limit_number").slideDown()
      } else {
        $(".user_reservation_limit_number").slideUp()
      }
    })
    $("select[name='user[reservation_limit_type]']").trigger('change');

    $("input[name='facility_use_setting']").change(function() {
      if ($("input[name='facility_use_setting']").prop("checked")) {
        $(".facility_use_list").slideDown()
      } else {
        $(".facility_use_list").slideUp()
      }
    })
    $("input[name='facility_use_setting']").trigger('change');

    $("#ngButton").click(function() {
      const facilityIds = document.querySelectorAll('.col-1.text-right input[type="hidden"]');
      var useType = "impossible";

      facilityIds.forEach(function(element) {
        var facilityid = "#" + element.value.trim();
        // select要素の選択肢を変更
        $(facilityid).val(useType);

      });

    });

    $("#okButton").click(function() {
      const facilityIds = document.querySelectorAll('.col-1.text-right input[type="hidden"]');
      var useType = "";

      facilityIds.forEach(function(element) {
        var facilityid = "#" + element.value.trim();
        // select要素の選択肢を変更
        $(facilityid).val(useType);
      });

    });

    const items = $(".col-4.text-right"); // 施設を取得
    const searchInput = $("#searchInput");

    searchInput.on("input", function() {   
      const noMatchMessage = $("#noMatchMessage")
      let hasMatch = false; // 部分一致が見つかったかどうかのフラグ 
      items.each(function() {
        if ($(this).text().includes(searchInput.val())) {
          $(this).parent().css("display", "block"); // 部分一致する場合、表示
          hasMatch = true; // 部分一致検出
        } else {
          $(this).parent().css("display", "none"); // 非表示
        }
      });
      if (!hasMatch) {
        noMatchMessage.css("display", "block");
      } else {
        noMatchMessage.css("display", "none"); // 部分一致が見つかった場合、メッセージを非表示にする
      }
    });
    
    $('#mynumber-modal').on('click', function(){
      document.getElementById( "old_mynumbercard" ).value = document.getElementById('mynumber_user_id_hidden').value;
      document.getElementById( "mynumbercard" ).value = document.getElementById('user_mynumber_user_id').value;
    })

  const mynumber_updated = function() {
    var close_flg = 0;
    var old_mynumber = document.getElementById('old_mynumbercard');
    var mynumber = document.getElementById('mynumbercard');

    if (mynumber.value.length > 0) {
      if (mynumber.value.length != 13) {
        alert('マイナンバーカード利用者IDは13文字で指定してください。')
        return
      } 

      if (String(mynumber.value).search(/^[-]?[0-9]+$/) != 0) {
        alert('マイナンバーカード利用者IDは半角数字で指定してください。')
        return
      }

      if (old_mynumber.value != mynumber.value) {
        $.ajax({
          url: '/users/mynumber_check',
          type: 'POST',
          data: { mynumber_user_id: mynumber.value },
          async: false,
          dataType: 'json',    
        })
        .done(function (data) {
          if (data == "0") {
            close_flg = 0;
          } else {
            alert('マイナンバーカード利用者IDは既に登録済みです。')
            close_flg = 1;
          }
        })
        .fail(function (){
          console.log('MyNumber: マイナンバーカード利用者IDのチェックに失敗しました。')
          close_flg = 2;
        });
      }
    }

    if (close_flg == 0) {
      update_mynumber_card_status(mynumber.value);
      document.getElementById("user_mynumber_user_id").value = mynumber.value;
      $('#users-form-modal').modal('hide');
    }
  }

  $('#mynumber-update').on('click', mynumber_updated);
  $('#mynumbercard').on('keydown', (e) => {
    if (e.key == 'Enter') {
      // 「登録する」クリックと同じ処理を行う
      mynumber_updated();
      return;
    }
  });

  $('#users-form-modal').on('keydown', (e) => {
    if (e.key == 'Enter') {
      // Enterキーで「利用者の追加/編集」画面の「保存する」が行われないよう、バブリングをmodalで止める。
      e.stopPropagation();
      e.preventDefault();
      return;
    }
  });
});

$(window).on('pageshow', function() {
    $("select[name='user[raffle_limit_type]']").trigger('change');
    $("select[name='user[reservation_limit_type]']").trigger('change');
    $("input[name='facility_use_setting']").trigger('change');
});